import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`So you just tried to pull changes from a remote repository and git presents you with the message: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`CONFLICT (content): Merge conflict in <filename>
Automatic merge failed; fix conflicts and then commit the result.
`}</code></pre>
    <p>{`From this message, you know that the automatic merge failed, that you need to fix some conflicts on some file and that you need to commit the result(or the fix).`}</p>
    <p>{`But what does all of this mean?`}</p>
    <h1 {...{
      "id": "reason-for-conflicts-to-happen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#reason-for-conflicts-to-happen",
        "aria-label": "reason for conflicts to happen permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reason for conflicts to happen`}</h1>
    <p>{`Usually, a conflict happens when the project was updated but your local repository is out of sync. So when you try to push changes on a file that someone already changed, a conflicting message will show, so you can choose which version is the right version to use.`}</p>
    <p>{`Let's say that you have the following file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`# hello
def hello():
    """Prints Hello"""
    print("Hello")

`}</code></pre>
    <p>{`Then someone comes and changes the file, but since you haven't pulled the changes from the main repository, these changes won't show up in your local repository. `}</p>
    <p>{`Then you decide to change this example function to print the traditional "Hello World". But when you try to push the changes to the main repository you get the message that a conflict happened in the hello.py file.`}</p>
    <p>{`Let's open the file and inspect the problem.`}</p>
    <h1 {...{
      "id": "the-conflicting-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-conflicting-file",
        "aria-label": "the conflicting file permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The conflicting file`}</h1>
    <p>{`When you open the hello.py file you notice that there are some strange things in the hello function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`def hello():
<<<<<<< HEAD
    """Prints Hello Everyone!"""
    print("Hello Everyone!")
=======
    """Prints Hello World!"""
    print("Hello World!")

`}</code></pre>
    <p>{`At first, this might seem a bit strange, but it's quite easy to understand. Basically, git is telling you that the HEAD of the main repository is showing the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`<<<<<<< HEAD
    """Prints Hello Everyone!"""
    print("Hello Everyone!")
`}</code></pre>
    <p>{`But your own repository has the following changes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`=======
    """Prints Hello World!"""
    print("Hello World!")

`}</code></pre>
    <p>{`As you can see, git is an amazing tool and it will tell you about issues before trying to do something. This conflict warning is asking you to check the differences between the two versions and choose which one do you want to use.`}</p>
    <h1 {...{
      "id": "fixing-the-conflict",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#fixing-the-conflict",
        "aria-label": "fixing the conflict permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fixing the conflict`}</h1>
    <p>{`Fixing the conflict is quite easy, all you need to do is delete the version that you don't want to use together with the `}<inlineCode parentName="p">{`<<<<<<< HEAD`}</inlineCode>{` and `}<inlineCode parentName="p">{`=======`}</inlineCode>{` markers. In the hello.py file you know that we should be printing "Hello World!" as per tradition, so you decide that your version is the correct version.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`def hello():
    """Prints Hello World!"""
    print("Hello World!")

`}</code></pre>
    <p>{`The hello.py will look like this when you fix the conflict. Now you can commit the changes and git will continue with the merge/pull command.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      